body{
    text-align:center;
    background-color: #282c34;
    /*background-color: #fff9f4;*/
    height: 100%;
    color: #F9D751;
    /*background-image: linear-gradient(to right,#FDF3A8 32%, #fff9f4 60% );*/
}

img {
    height: 40vmin;
    pointer-events: none;
}

a {
color: #F9D751;
}